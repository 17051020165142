import React from 'react'

const Footer = (props) => (
    <div id="wrapper">
        <footer id="footer">
            <div className="inner">
                <ul className="icons">
                    {/* <li><a href="#" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li> */}
                    <li><a href="https://www.facebook.com/modsquad.io/" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                    <li><a href="https://www.instagram.com/modsquad.io" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
                    {/* <li><a href="#" className="icon alt fa-github"><span className="label">GitHub</span></a></li> */}
                    {/* <li><a href="#" className="icon alt fa-linkedin"><span className="label">LinkedIn</span></a></li> */}
                </ul>
                <ul className="copyright">
                    <li>&copy; modsquad,llc</li><li>Design: <a href="https://modsquad.io">the MODsquad</a></li>
                </ul>
            </div>
        </footer>
    </div>
  )

export default Footer
