import React from 'react'

const Banner = (props) => (
  <div id='wrapper2'>
      <section id="banner" className="major">
          <div className="inner">
              {/* <header className="major"> */}
              {/* </header> */}
              <div className="content">
                  <h1>Making the experience</h1>
                  <p style={{marginBottom:-10}} >with your business matter</p>
              </div>
              <div style={{ marginTop: 470, marginLeft:100 }}>
                  <ul className="actions">
                      <li><a href="#two" className="button next scrolly">Get Started</a></li>
                  </ul>
              </div>
          </div>
      </section>
  </div>
)

export default Banner
