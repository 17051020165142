import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Header = (props) => (
    <div id='wrapper'>
        <header id="header" className="alt">
            <Link to="/" className="logo"><strong>MODSQUAD</strong> <span>powered by HELIX</span></Link>
            {/* <nav>
                <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
            </nav> */}
        </header>
    </div>
)

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
